/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  featureFlagsApiRef,
  useApi,
  attachComponentData,
} from '@backstage/core-plugin-api';
import React, { ReactNode } from 'react';

/**
 * Props for the {@link FeatureFlagged} component.
 *
 * @public
 */
export type FeatureFlaggedProps = { children: ReactNode } & (
  | { with: string }
  | { without: string }
);

/**
 * Enables or disables rendering of its children based on the state of a given
 * feature flag.
 *
 * @public
 */
export const FeatureFlagged = (props: FeatureFlaggedProps) => {
  const { children } = props;
  const featureFlagApi = useApi(featureFlagsApiRef);
  const isEnabled =
    'with' in props
      ? featureFlagApi.isActive(props.with)
      : !featureFlagApi.isActive(props.without);
  return <>{isEnabled ? children : null}</>;
};

attachComponentData(FeatureFlagged, 'core.featureFlagged', true);
